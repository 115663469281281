<template>
  <v-text-field
    :key="language.value"
    v-model="text"
    :lang="language.value"
    v-bind="$attrs"
    @change="$emit('update:value', text)"
  >
    <template #prepend>
      <country-flag
        :country="language.flag || language.value"
        class="mt-n2"
        :size="flagSize"
      />
    </template>
    <template
      v-if="translateButton"
      #append
    >
      <v-btn
        icon
        small
        tile
        @click="translateText(englishText, language.value, v => text = v)"
      >
        <v-icon small>
          fa fa-language
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>
<script>

export default {
  components: {
    CountryFlag: () => import('vue-country-flag'),
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    flagSize: {
      type: String,
      default: null,
    },
    englishText: {
      type: String,
      default: '',
    },
    translateButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      text: this.value,
    }
  },
  watch: {
    value () {
      this.text = this.value
    },
  },
}
</script>
